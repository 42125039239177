import {
  EyeIcon,
  ListBulletIcon,
  Square3Stack3DIcon,
  QuestionMarkCircleIcon,
  MegaphoneIcon,
  CreditCardIcon,
  TagIcon,
  MapIcon,
  LightBulbIcon,
  Cog6ToothIcon,
  UsersIcon,
  EllipsisHorizontalCircleIcon,
} from "@heroicons/react/24/outline";
import { Link } from "gatsby";
import React from "react";

import { articleTypes } from "../../constants/article-types";

const Topics = ({ articleMap }) => {
  return (
    <div className="max-w-7xl mx-auto px-6 py-16">
      <div className="py-12">
        <div className="mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <dl className="space-y-10 lg:grid lg:grid-cols-3 lg:gap-10 lg:space-y-0">
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-yellow-500 text-white">
                  <EyeIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Portal
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.PORTAL].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-yellow-700 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-yellow-500 text-white">
                  <ListBulletIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Posts
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.POSTS].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-yellow-700 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-yellow-500 text-white">
                  <Square3Stack3DIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Topics
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.TOPICS].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-yellow-700 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-yellow-500 text-white">
                  <TagIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Tags
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.TAGS].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-yellow-700 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-yellow-500 text-white">
                  <MapIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Roadmap
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.ROADMAP].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-yellow-700 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-yellow-500 text-white">
                  <MegaphoneIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Changelog
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.CHANGELOG].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-yellow-700 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-yellow-500 text-white">
                  <LightBulbIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Smart embed
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.SMART_EMBED].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-yellow-700 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-yellow-500 text-white">
                  <UsersIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Team
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.TEAM].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-yellow-700 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-yellow-500 text-white">
                  <Cog6ToothIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Settings
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.SETTINGS].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-yellow-700 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-yellow-500 text-white">
                  <CreditCardIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Billing
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.BILLING].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-yellow-700 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-yellow-500 text-white">
                  <EllipsisHorizontalCircleIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Other
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  {articleMap[articleTypes.OTHER].map((article) => (
                    <li className="leading-5">
                      <Link
                        className="text-yellow-700 hover:underline"
                        to={article.slug}
                      >
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-yellow-500 text-white">
                  <QuestionMarkCircleIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  Need additional support?
                </p>
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                <ul className="space-y-3">
                  <li className="leading-5">
                    <a
                      className="text-yellow-700 hover:underline"
                      href="https://featurefind.io/contact"
                    >
                      Get in touch with us
                    </a>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Topics;
