import { graphql } from "gatsby";
import get from "lodash/get";
import React from "react";

import Header from "../components/header";
import Hero from "../components/hero";
import Footer from "../components/footer";
import Metadata from "../components/metadata";
import Topics from "../components/topics";
import { mapArticles } from "../utils/map-articles";

const IndexPage = ({ data, location }) => {
  const articles = get(data, "allMarkdownRemark.nodes", []);
  const articleMap = mapArticles(articles);

  return (
    <>
      <Header />
      <Metadata title="Help Center - FeatureFind" />
      <Hero search={location.search} />
      <Topics articleMap={articleMap} />
      <Footer />
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
`;
