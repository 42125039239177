import { articleTypes } from "../constants/article-types";

export const mapArticles = (articles) => {
  const articleMap = {
    [articleTypes.PORTAL]: [],
    [articleTypes.POSTS]: [],
    [articleTypes.TOPICS]: [],
    [articleTypes.TAGS]: [],
    [articleTypes.ROADMAP]: [],
    [articleTypes.CHANGELOG]: [],
    [articleTypes.SMART_EMBED]: [],
    [articleTypes.TEAM]: [],
    [articleTypes.SETTINGS]: [],
    [articleTypes.BILLING]: [],
    [articleTypes.OTHER]: [],
  };

  for (let i = 0; i < articles.length; i++) {
    const article = articles[i];
    const slug = article.fields.slug;

    if (slug.startsWith(`/${articleTypes.PORTAL}/`)) {
      articleMap[articleTypes.PORTAL].push({
        title: article.frontmatter.title,
        slug,
      });
    } else if (slug.startsWith(`/${articleTypes.POSTS}/`)) {
      articleMap[articleTypes.POSTS].push({
        title: article.frontmatter.title,
        slug,
      });
    } else if (slug.startsWith(`/${articleTypes.TOPICS}/`)) {
      articleMap[articleTypes.TOPICS].push({
        title: article.frontmatter.title,
        slug,
      });
    } else if (slug.startsWith(`/${articleTypes.TAGS}/`)) {
      articleMap[articleTypes.TAGS].push({
        title: article.frontmatter.title,
        slug,
      });
    } else if (slug.startsWith(`/${articleTypes.ROADMAP}/`)) {
      articleMap[articleTypes.ROADMAP].push({
        title: article.frontmatter.title,
        slug,
      });
    } else if (slug.startsWith(`/${articleTypes.CHANGELOG}/`)) {
      articleMap[articleTypes.CHANGELOG].push({
        title: article.frontmatter.title,
        slug,
      });
    } else if (slug.startsWith(`/${articleTypes.SMART_EMBED}/`)) {
      articleMap[articleTypes.SMART_EMBED].push({
        title: article.frontmatter.title,
        slug,
      });
    } else if (slug.startsWith(`/${articleTypes.TEAM}/`)) {
      articleMap[articleTypes.TEAM].push({
        title: article.frontmatter.title,
        slug,
      });
    } else if (slug.startsWith(`/${articleTypes.SETTINGS}/`)) {
      articleMap[articleTypes.SETTINGS].push({
        title: article.frontmatter.title,
        slug,
      });
    } else if (slug.startsWith(`/${articleTypes.BILLING}/`)) {
      articleMap[articleTypes.BILLING].push({
        title: article.frontmatter.title,
        slug,
      });
    } else if (slug.startsWith(`/${articleTypes.OTHER}/`)) {
      articleMap[articleTypes.OTHER].push({
        title: article.frontmatter.title,
        slug,
      });
    }
  }

  return articleMap;
};
